<template>
	<div class="full-height flex-column">

		<div
			class="mt-50 pa-10-20"
		>
			<DatePicker class="mt-20" type="year" :date="subscrp_year" @click="setDate($event)" ></DatePicker>
			<div
				class="mt-30"
			>
				<ul
					class="size-px-14"
				>
					<li
						v-for="(history, h_index) in items_list"
						:key="'history_' + h_index"
						class="under-line-not-last pb-20 mb-20"
						@click="onDetail(history)"
					>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.common.nickName }} <!-- 닉네임 --></div>
							<div>{{ history.nickname }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_subscribe_product }} <!-- 구독상품 --></div>
							<div>{{ history.subscrp_plan_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_type }} <!-- 결제 수단 --></div>
							<div>{{ history.payment_mthd_name }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_quntity }} <!-- 결제수량 --></div>
							<div>{{ history.amount }}</div>
						</div>
						<div
							class="justify-space-between items-center mt-10"
						>
							<div>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></div>
							<div>{{ history.pmtdate }}</div>
						</div>
					</li>
				</ul>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_detail"
		>
			<template
				v-slot:body
			>
				<div
					class=" bg-white flex-column justify-space-between radius-20"
				>
					<div class="size-px-14 pa-20">
						<div class="size-px-20 text-center font-weight-bold under-line pb-20">{{ $language.point_result.title_payment_detail }} <!-- 결제 상세내역 --></div>

						<div class="mt-30">
							<h4>{{ $language.point_result.title_information }} <!-- 정보 --></h4>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.common.nickName }} <!-- 닉네임 --></div>
								<div>{{ item.nickname }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_subscribe_product }} <!-- 구독상품 --></div>
								<div>{{ item.subscrp_plan_name }}</div>
							</div>
						</div>
						<div class="mt-30">
							<h4>{{ $language.point_result.title_payment_information }} <!-- 결제정보 --></h4>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_tid }} <!-- 거래번호 --></div>
								<div>{{ item.nickname }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_number }} <!-- 승인번호 --></div>
								<div>{{ item.subscrp_plan_name }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_type }} <!-- 결제 수단--></div>
								<div>{{ item.subscrp_plan_name }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.subscribe.txt_subscribe_complete }} <!-- 결제 금액 --></div>
								<div>{{ item.amount | makeComma }}</div>
							</div>
							<div class="justify-space-between text-left mt-10">
								<div>{{ $language.point_result.title_payment_date }} <!-- 거래일시 --></div>
								<div>{{ item.pmtdate | transDateTime }}</div>
							</div>
						</div>
					</div>

					<div class="mt-auto">
						<button
							@click="offDetail"
							class="width-100 bg-blue-mafia pa-10 color-white size-px-16 "
						>{{ $language.point_result.btn_confirm }}<!-- 확인 --></button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<script>

import PopupLayer from "@/view/Layout/PopupLayer";
import DatePicker from "@/components/DatePicker";

export default {
	name: 'SubscribeHistory'
	, components: {DatePicker, PopupLayer}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: 'payment list'
				, title: '결제내역'
				, not_footer: true
				, type: 'mypage'
			}
			, item: {
				point: 0
			}
			, items: [
			]
			, is_on_detail: false
			, subscrp_year: this.$date.init().year
		}
	}
	, computed: {
		items_list: function(){
			return this.items.filter((item) => {
				if(item.payment_coin_quantity > 0){
					item.amount = item.payment_coin_quantity
				}else{
					item.amount = item.last_payment_amount
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_history
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_member_number: this.$route.params.m_id
						, subscrp_year: this.subscrp_year + ''
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.payment_particulars_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPayment: function(item){
			this.is_on_payment = true
			this.item_payment = item
		}
		, offPayment: function(){
			this.is_on_payment = false
			this.item_payment = {}
		}
		, to: function(type){
			this.$bus.$emit('to', { name: 'PointHistory', params: { type: type}})
		}
		, onDetail: function(item){
			this.is_on_detail = true
			this.item = item
		}
		, offDetail: function(){
			this.is_on_detail = false
			this.item = {}
		}
		, setDate: function(date){
			this.subscrp_year = date
			this.getData()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_payment_history'){
				this.$emit('to', { name: 'PaymentHistory'})
			}
		})
	}
}
</script>
